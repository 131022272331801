
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "search-table",
  components: {},

  props: {
    tableId: String,
  },

  data() {
    return {};
  },
  setup(props) {
    var tableIdName = props.tableId;
    return {
      tableIdName,
    };
  },
  methods: {
    searchTable() {
      var input;
      input = document.getElementById("searchTableInput");
      Datatable.search(input.value);

      // var input, filter, dataTableID, found, table, tr, td, i, j;
      // input = document.getElementById("searchTableInput");
      // filter = input.value.toUpperCase();
      // dataTableID = document.getElementById("datatableId");
      // table = dataTableID.getElementsByTagName("table")[0];
      // tr = table.getElementsByTagName("tr");
      // for (i = 0; i < tr.length; i++) {
      //   td = tr[i].getElementsByTagName("td");
      //   for (j = 0; j < td.length; j++) {
      //     if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
      //       found = true;
      //     }
      //   }
      //   if (found) {
      //     tr[i].style.display = "";
      //     found = false;
      //   } else {
      //     tr[i].style.display = "none";
      //   }
      // }
    },
  },
});
