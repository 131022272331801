
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchTable from '@/components/wizard/SearchTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import { apiEndpoint } from '@/mixin/apiMixin.js';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import AssessorEditDrawer from '@/layout/header/partials/assessor/AssessorListEditDrawer2.vue';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'assessor-list',
  components: {
    Form,
    Field,
    Datatable,
    SearchTable,
    OrganizationModal,
    AssessorEditDrawer,
  },
  data() {
    return {
      adminCheck: false,
      load: false,
      api_url: '',
      batch: {
        tranche: '' as any,
        entity_id: '' as any,
        institute_info_id: '',
        active_status: '',
      } as any,
      tranche: [],
      btnCheck: false,
      association: [] as any,
      institutes: [],
      employee: {},
      details: {},
      instituteList: [],
      weekplan: {},
      entityTypes: [],
      tranches_info: [],
      actionEdit: false,
      actionDelete: false,
      industry: [],
      entityInfos: [],
      divisions: [],
      actionActive: false,
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '180px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Unique Id',
          key: 'uniqueId',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Assessor Name',
          key: 'assessor_name',
          sortable: true,
        },
        {
          name: 'Unique Number',
          key: 'unique_number',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Area of Expertise',
          key: 'area_of_exp',
          sortable: true,
        },
        // {
        //   name: 'Courses',
        //   key: 'courses',
        //   sortable: true,
        // },
        {
          name: 'Years of Exp.',
          key: 'years_of_exp',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
      ],
      assessorList: {},

      tableData: [
        {
          id: 1,
          assessor_name: 'Muhammad Asif Atick ',
          gender: 'male',
          phone: '01714005887',
          email: 'jossy81@gmail.com',
          area_expertise: 'ASP.Net, C#, Database Design, ASP.Net MVC ',
          status: 'Md. Yameen Hossain',
        },
        {
          id: 2,
          assessor_name: 'Nazmus Saquibe',
          gender: 'Male',
          phone: '01534666175',
          email: 'nazmus.saquibe@chorki.com',
          area_expertise:
            'Programming Language: Java SE, C/C++, C# • Web Development: PHP5, HTML5, DHTML, XHTML, .NET, XML, JavaScript, Web Service, AJAX and JQuery.',
          status: 'Md. Yameen Hossain ',
        },
      ],
      componentKey: 0,
      loading: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      lists: [],
      search: '',
      data: {},
      organizations: [],
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      entityIds: [] as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getAssociation();
    await this.getTranche();
    this.checkAdmin();
    await this.actioncheck();
    this.emitter.on('assessor-updated', async () => {
      this.assessorInfo();
    });
  },
  methods: {
    async printPdf() {
      let institute_info_id = this.batch.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_info_id', this.batch.entity_id);
      formData.set('tranche_id', this.batch.tranche);
      formData.set('institute_info_id', institute_info_id);
      formData.set('active_status', this.batch.active_status);
      axios
        .post(`${this.VUE_APP_API_URL}/api/report/assessor_listprint`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let institute_info_id = this.batch.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_info_id', this.batch.entity_id);
      formData.set('tranche_id', this.batch.tranche);
      formData.set('institute_info_id', institute_info_id);
      formData.set('active_status', this.batch.active_status);

      await ApiService.post('report/assessor_listpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let institute_info_id = this.batch.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_info_id', this.batch.entity_id);
      formData.set('tranche_id', this.batch.tranche);
      formData.set('institute_info_id', institute_info_id);
      formData.set('active_status', this.batch.active_status);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/assessor_listexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'assesorlist.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    filterEntity(entity) {
      this.trainingInstitute();
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.batch.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.batch.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async btnCheckStatus() {
      this.btnCheck = true;
    },
    async formSubmit() {
      //form submit
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    checkAdmin() {
      if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
        let user = VueCookieNext.getCookie('_seip_user');
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          'Admin'
        ) {
          this.adminCheck = true;
        }
      }
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          this.tempAssociation = this.association;

          this.association.forEach((val, index) => {
            this.tempId.push(val.id);
          });
          this.association.unshift(this.selectObj);
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    Suspend(id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      Swal.fire({
        title: 'Are you sure you want to suspend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, suspend!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'entity/assessor/status-change?assessor_id=' +
              id +
              '&active_status=4' +
              '&responsible_user_id=' +
              user_id.id
          )
            .then((response) => {
              //this.assessorInfo();
              Swal.fire('Suspended!', response.data.message, 'success').then(
                (result) => {
                   if (result.isConfirmed) {
                   this.emitter.emit('assessor-updated', true);
                  }
                }
              );
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Active(id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      Swal.fire({
        title: 'Are you sure you want to Activate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'entity/assessor/status-change?assessor_id=' +
              id +
              '&active_status=1' +
              '&responsible_user_id=' +
              user_id.id
          )
            .then((response) => {
              //this.assessorInfo();
              Swal.fire('Activated!', response.data.message, 'success').then(
               (result) => {
                   if (result.isConfirmed) {
                   this.emitter.emit('assessor-updated', true);
                  }
                }
              );
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Inactive(id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      Swal.fire({
        title: 'Are you sure you want to inactive it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Inactive!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'entity/assessor/status-change?assessor_id=' +
              id +
              '&active_status=3' +
              '&responsible_user_id=' +
              user_id.id
          )
            .then((response) => {
              //this.assessorInfo();
              Swal.fire('Inactivated!', response.data.message, 'success').then(
                (result) => {
                   if (result.isConfirmed) {
                   this.emitter.emit('assessor-updated', true);
                  }
                }
              );
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Assessor') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Edit') {
                console.log(actions[j].action_name);
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Delete') {
                console.log(actions[j].action_name);
                this.actionDelete = true;
              }
              if (actions[j].action_name === 'Approve') {
                // console.log(actions[j].action_name);
                this.actionActive = true;
              }
            }
          }
        }
      }
    },

    edit(data) {
      console.log(data);
      this.emitter.emit('edit-assessor-modal-data', data);
    },
    // async getAssessorList() {
    //   await ApiService.get("resource/assessorlist")
    //     .then((response) => {
    //       this.assessorList = response.data;
    //       console.log(response);
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },

    // async trainingInstitute() {
    //   this.load = true;
    //   this.entityIds = this.batch.entity_id;
    //   if (this.batch.entity_id.includes("select")) {
    //     this.entityIds = this.tempId;
    //   }
    //   if (this.batch.entity_id.includes("unselect")) {
    //     this.entityIds = -1;
    //   }
    //   await ApiService.get("institute/list?entity_id=" + this.entityIds)
    //     .then((response) => {
    //       this.institutes = response.data.data;
    //       this.load = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async trainingInstitute() {
      this.load = true;
      this.entityIds = this.batch.entity_id;
      if (this.batch.entity_id.includes('select')) {
        this.entityIds = this.tempId;
      }
      if (this.batch.entity_id.includes('unselect')) {
        this.entityIds = -1;
      }
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.entityIds != '') {
        await ApiService.get(
          'institute/list?entity_id=' +
            this.entityIds +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.institutes = response.data.data;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async assessorInfo() {
      let institute_info_id = this.batch.institute_info_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.batch.tranche.length > 0) {
        if (this.batch.entity_id.length > 0) {
          this.load = true;
          await ApiService.get(
            'entity/assessor/list?tranche_id=' +
              this.batch.tranche +
              '&entity_info_id=' +
              this.batch.entity_id +
              '&institute_info_id=' +
              institute_info_id +
              '&active_status=' +
              this.batch.active_status
          )
            .then((response) => {
              this.load = false;
              // console.log(response);
              this.assessorList = response.data.data;

              this.showCourseNotice = true;
              this.componentKey += 1;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        } else {
            Swal.fire({
            title: 'Warning!',
            html: 'Please Select Training Partner',
            icon: 'warning',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        }
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please Select Tranche',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    view(infos) {
      this.data = infos;
    },
    courseNotice() {
      if (Object.keys(this.assessorList).length === 0) {
        console.log('No Assessor List');
        this.load = true;
        this.assessorInfo().then(() => {
          this.load = false;
          this.showCourseNotice = true;
        });
      } else {
        this.showCourseNotice = true;
      }
    },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?' + id,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/assessor/delete?assessor_id=' + `${id}`)
            .then((response) => {
              this.emitter.emit('infos-updated', true);
              this.assessorInfo();
              this.componentKey += 1;
              Swal.fire('Deleted!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
